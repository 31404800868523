import React from "react";
import "./header.css";
import { NavLogo } from "../assets";

const Header = ({ onContactClick }) => {
  return (
    <div className="video-container">
      <video className="video no-controls" autoPlay loop muted playsInline>
        <source src={require("../../assets/RE.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay-bg">
        <div>
          <div className="logo-div">
            <img src={NavLogo} className="logo" alt="logo" />
            <p className="logo-text">MY RE TASKER</p>
          </div>
        </div>
        <div className="bottom-right">
          <p className="service-text">
            Enhancing Real Estate Agents’ Efficiency with Customized Support
            Services
          </p>
          <button className="btn" onClick={onContactClick}>
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
