import React, { useState } from "react";
import "./service.css";
import {
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  service7,
  close,
} from "../assets";
import Modal from "@mui/material/Modal";
const servicesData = [
  {
    id: 1,
    title: "Listing Coordination",
    description:
      "Our listing coordination services ensure that your brand and your listings will look good online, giving you the best possible exposure. It includes following core services and more",
    image: service1,
    list: [
      "Listing paperwork package creation. (In Dotloop, KWCommand, Docusign etc.)",
      "Consolidate, file & manage all the property related documents and pictures in Google drive, Dropbox etc.",
      "List the property in MLS along with photos & available attachments and save as an incomplete, send for the review.",
      `Post "Coming Soon" listing on Zillow, Realtor, Trulia etc.`,
      "Promote custom flyers (Just Listed & Openhouse) on - Craigslist, LinkedIn, Facebook etc.",
      "Post Input MLS support - setup showings, status updates and open house setup etc.",
      "Post listing enhance/update the property on major platforms like Zillow, Realtor etc.",
      "Compile showing feedback report weekly for the property.",
      `Order "Sign" and "Property Photos" if required.`,
      "Update the Listing on your CRM (Brivity, TopProducer, KWCommand etc.)",
    ],
  },
  {
    id: 2,
    title: "Transaction Coordination",
    description:
      "Our experienced TC's will effortlessly handle all your contract-to-close transaction documents and procedures. It includes following core services and more",
    image: service2,
    list: [
      "Prepare and manage offer packages.",
      "Handle digital signing and filing of important documents.",
      "Review offer and contract documents for brokerage compliance.",
      `Create and distribute "Under Contract" flyers.`,
      "Send introductory emails to all concerned parties upon mutual acceptance.",
      "Manage and send critical deadlines and reminders.",
      "Communicate with lenders, title companies, and attorneys.",
      "Request and review draft ALTA/HUD statements.",
      "Prepare Commission Disbursement Authorization (CDA) documents.",
      `Create and distribute "SOLD" flyers.`,
      "Promote properties online through social media platforms.",
      "Provide CMA reports after 180 days of closing with updated market values.",
      "Schedule property viewings and showings.",
    ],
  },
  {
    id: 3,
    title: "Branding & Marketing",
    description:
      "Our technology & design teams are proficient at creating custom online/offline marketing pieces for your brand. It includes following core services and moree",
    image: service3,
    list: [
      "Logo design.",
      "Business card design (two-sided).",
      "Letterhead design.",
      "Single-page and two-sided brochure design.",
      "Two-sided tri-fold brochure design.",
      "Postcard design in various sizes.",
      "Static (image banner) and dynamic (HTML) email signature design.",
      "Property flyer design for social media and print.",
      "Event flyer design for social media and print.",
      "Social media graphic design for standard posts and stories.",
      "Online ads and campaign management for property listings",
    ],
  },
  {
    id: 4,
    title: "Admin Services",
    description:
      "Get much needed help in managing your day-to-day aspects of your real estate business. It includes following core services and more",
    image: service4,
    list: [
      "Hourly administrative support.",
      "Document preparation.",
      "Appointment scheduling.",
      "Data entry services.",
      "Customer follow-up.",
    ],
  },
  {
    id: 5,
    title: "Property Management",
    description:
      "Get full support for manging your properties throughout the property management flow. It includes following core services and more",
    image: service5,
    list: [
      "Tenant screening.",
      "Lease agreement preparation.",
      "Rent collection services.",
      "Maintenance request management.",
      "Property inspection reports.",
    ],
  },
  {
    id: 6,
    title: "Accounting Services",
    description:
      "Our technology & design teams are proficient at creating custom online/offline marketing pieces for your brand. It includes following core services and more",
    image: service6,
    list: [
      "Bookkeeping services.",
      "Invoice management.",
      "Payroll processing.",
      "Tax preparation.",
      "Financial reporting.",
    ],
  },
  {
    id: 7,
    title: "Compliance Review",
    description:
      "Get full support for manging your properties throughout the property management flow. It includes following core services and more",
    image: service7,
    list: [
      " Review listing paperwork for compliance issues.",
      "Review offer paperwork for compliance issues.",
      "Review transaction documents for compliance issues.",
      "Prepare and manage compliance documents.",
    ],
  },
];
const Services = ({ isVisible }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const handleOpen = (item) => {
    setServiceData(item);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  if (!isVisible) return null;

  return (
    <div id="service-container" className={`service-container`}>
      <div className={`main-service`}>
        <p className="inner-main-service">SERVICES</p>
        <p className="inner-services">SERVICES</p>
      </div>

      {servicesData.map((item) => {
        return item.id % 2 === 0 ? (
          <div className="services-div-2" key={item.id}>
            <div className="services-card-2">
              <h3 className="services-title">{item.title}</h3>
              <div className="services-desc-div">
                <p className="services-description">{item.description}</p>
                <button
                  className="read-more-btn"
                  onClick={() => handleOpen(item)}
                >
                  Read More
                </button>
              </div>
            </div>
            <img className="service-img" src={item.image} alt="service" />
          </div>
        ) : (
          <div className={"services-div"} key={item.id}>
            <img className="service-img" src={item.image} alt="service" />
            <div className={"services-card"}>
              <h3 className="services-title">{item.title}</h3>
              <div className="services-desc-div">
                <p className="services-description">{item.description}</p>
                <button
                  className="read-more-btn"
                  onClick={() => handleOpen(item)}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        );
      })}
      
      <Modal open={modalOpen} onClose={handleClose}>
        <div className="modalBox">
          <div className="img-div">
            <img
              src={close}
              className="modalClose"
              onClick={handleClose}
              alt="close-icon"
            />
          </div>
          <div className="modaltitle">
            <p className="setTitle">{serviceData?.title}</p>
          </div>
          <div className="overflow-auto">
            <div className="info-div">
              <p className="infoTxt">{serviceData?.description}</p>
            </div>
            <div className="list-div">
              <ul className="list-style-type">
                {serviceData?.list?.map((list, index) => {
                  return <li key={index + 1}>{list}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Services;
