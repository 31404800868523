// App.js

import React from "react";
import Home from "./pages/home-page/Home";
import Term from "./pages/term/Term";
import Privacy from "./pages/privacy/Privacy";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/term&condition",
    element: <Term />,
  },
  {
    path: "/privacypolicy",
    element: <Privacy />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
