import React, { useEffect } from "react";
import { Modal } from "@mui/material";
import { app_store, close, iPhone, macBook, play_store } from "../assets";
import "./efficient_support.css";
import { supabase } from "../../SupabaseConfig";
import { CircularProgress, Checkbox } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function EfficientSupport() {
  const location = useLocation();
  const [serviceModal, setServiceModal] = useState(false);
  const [termModal, setTermModal] = useState(false);
  const [ndaModal, setNdaModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [value, setValue] = useState("Listing Coordination");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactError, setContactError] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleOpen = () => {
    setServiceModal(true);
  };

  const handleTermOpen = () => {
    setTermModal(true);
  };
  const handleNDAOpen = () => {
    setNdaModal(true);
  };
  const handlePolicyOpen = () => {
    setPolicyModal(true);
  };
  const handleClose = () => {
    setServiceModal(false);
    setEmailError("");
    setContactError("");
    setNameError("");
    setEmail("");
    setContact("");
    setName("");
    setChecked(false);
  };

  const handleTermClose = () => {
    setTermModal(false);
  };
  const handleNDAClose = () => {
    setNdaModal(false);
  };
  const handlePolicyClose = () => {
    setPolicyModal(false);
  };
  const formValidation = () => {
    if (
      name != "" &&
      /\S+@\S+\.\S+/.test(email) &&
      contact.length === 10 &&
      checked
    ) {
      waitListFunction();
    } else {
      if (name == "") {
        setNameError("Enter Your Name");
      } else {
        setNameError("");
      }
      if (contact.length !== 10) {
        setContactError("Enter your contact");
      } else {
        setContactError("");
      }
      if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Enter Valid Email");
      } else {
        setEmailError("");
      }

      if (!checked) {
        alert("Please accept the terms and conditions to proceed.");
      }
    }
  };
  const NDAList = [
    {
      title: "Definition of Confidential Information.",
      description:
        'For the purposes of this Agreement, "Confidential Information" shall mean any and all non-public information, including, without limitation, business plans, strategies, client information, trade secrets, and any other information which is not generally known to the public, disclosed by Disclosing Party to Receiving Party.',
    },
    {
      title: "Obligations of the Receiving Party.",
      description: "Receiving Party agrees to:",
      list: [
        "Maintain the Confidential Information in strict confidence;",
        "Not disclose the Confidential Information to any third parties without the prior written consent of Disclosing Party;",
        "Use the Confidential Information solely for the intended purpose within the brokerage;",
        "Protect the Confidential Information with the same degree of care as it uses to protect its own confidential information, but in no case less than reasonable care;",
      ],
    },
    {
      title: "Exclusions from Confidential Information.",
      description:
        "Confidential Information shall not include information which:",
      list: [
        "Is or becomes publicly known through no fault of Receiving Party;",
        "Is lawfully received from a third party without restriction and without breach of this Agreement;",
        "Is independently developed by the Receiving Party without use of or reference to the Confidential Information.",
      ],
    },
    {
      title: "Term.",
      description:
        "The obligations of this Agreement shall be in effect for a period of 5 years from the date of disclosure of the Confidential Information.",
    },
    {
      title: "Return of Confidential Information.",
      description:
        "Upon termination of this Agreement, or upon the Disclosing Party's written request, the Receiving Party shall promptly return or destroy all copies of Confidential Information in its possession.",
    },
    {
      title: "Governing Law.",
      description:
        "This Agreement shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of laws principles",
    },
  ];
  const TermsList = [
    {
      title: "Introduction",
      description:
        "Welcome to MY RE TASKER, a platform operated by Worldwide Assistance Solutions LLC. By accessing or using our services (the 'Service'), you agree to be bound by these Terms & Conditions (the 'Terms'). Please read them carefully.",
    },
    {
      title: "Definitions",
      description: "",
      list: [
        '"Company" refers to Worldwide Assistance Solutions LLC, dba MY RE TASKER.',
        `"Service" refers to the Company's SAAS platform available via website and mobile applications.`,
        '"User", "You, and Your" refer to the individual accessing or using the Service.',
      ],
    },
    {
      title: "Acceptance of Terms",
      description:
        "By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use the Service.",
    },
    {
      title: "Eligibility",
      description:
        "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
    },
    {
      title: "Account Registration",
      description:
        "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
    },
    {
      title: "License to Use",
      description:
        "The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
    },
    {
      title: "Prohibited Conduct",
      description:
        "You agree not to engage in any of the following prohibited activities:",
      list: [
        "Using the Service for any illegal purpose.",
        "Attempting to interfere with the proper functioning of the Service.",
        "Using automated systems to access the Service without the Company's prior written permission.",
        "Attempting to gain unauthorized access to any part of the Service.",
      ],
    },
    {
      title: "Fees and Payment",
      description:
        "Some features of the Service may require payment of fees. You agree to pay all applicable fees associated with your use of the Service. The Company reserves the right to change its pricing and fees at any time.",
    },
    {
      title: "Intellectual Property",
      description:
        "All content, trademarks, service marks, trade names, logos, and other intellectual property displayed on the Service are the property of the Company or its licensors. You may not use any of these without the Company's prior written consent.",
    },
    {
      title: "Privacy Policy",
      description:
        "Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.",
    },
    {
      title: "Termination",
      description:
        "The Company may terminate or suspend your access to the Service at any time, with or without cause or notice, effective immediately. Upon termination, your right to use the Service will immediately cease.The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
    },
    {
      title: "Disclaimers",
      description:
        'The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. The Company disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.',
    },
    {
      title: "Limitation of Liability",
      description:
        "In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Service; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party; or (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not the Company has been advised of the possibility of such damages.",
    },
    {
      title: "Governing Law",
      description:
        "These Terms shall be governed and construed in accordance with the laws of the State of Florida, without regard to its conflict of law provisions.",
    },
    {
      title: "Changes to Terms",
      description:
        "The Company reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
    },
    {
      title: "Contact Us",
      description: `If you have any questions about these Terms, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: Info@myretasker.com`,
    },
  ];
  const waitListFunction = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("waitlist")
      .insert([{ name: name, contact_no: contact, email: email }])
      .select();
    if (data) {
      handleClose();
      setLoading(false);
      setEmailError("");
      setContactError("");
      setContactError("");
      setEmail("");
      setContact("");
      setName("");
      alert(
        "Your request to join our waitlist has been successfully submitted. We appreciate your enthusiams and can't wait to share more exciting updates with you soon. Stay tuned!"
      );
    } else {
      setLoading(false);
    }
  };

  const privacyList = [
    {
      title: "Introduction",
      content: `Worldwide Assistance Solutions LLC, dba MY RE TASKER ("Company," "we," "us," or "our"), operates the MY RE TASKER platform available via website and mobile applications. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service.`,
    },
    {
      title: "Information We Collect",
      content:
        "We may collect information about you in a variety of ways. The information we may collect includes:",
      subsections: [
        {
          num: "2.1",
          title: "Personal Data",
          content:
            "Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Service or when you choose to participate in various activities related to the Service.",
        },
        {
          num: "2.2",
          title: "Derivative Data",
          content:
            "Information our servers automatically collect when you access the Service, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Service.",
        },
        {
          num: "2.3",
          title: "Financial Data",
          content:
            "Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Service.",
        },
        {
          num: "2.4",
          title: "Mobile Device Data",
          content:
            "Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device if you access the Service from a mobile device.",
        },
      ],
    },
    {
      title: "Use of Your Information",
      content: `Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Service to:`,
      list: [
        "Create and manage your account",
        "Process your transactions",
        "Personalize your experience with the Service",
        "Improve our Service",
        "Send you promotional information",
        "Respond to your comments, questions, and customer service requests",
        "Protect against fraudulent, unauthorized, or illegal activity",
        "Monitor and analyze usage and trends to improve your experience with the Service",
      ],
    },
    {
      title: "Disclosure of Your Information",
      content:
        "We may share information we have collected about you in certain situations. Your information may be disclosed as follows:",
      subsections: [
        {
          num: "4.1",
          title: "By Law or to Protect Rights",
          content:
            "If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.",
        },
        {
          num: "4.2",
          title: "Business Transfers",
          content:
            "We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
        },
        {
          num: "4.3",
          title: "Third-Party Service Providers",
          content:
            "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.",
        },
        {
          num: "4.4",
          title: "Marketing Communications",
          content:
            "With your consent, we may share your information with third parties for marketing purposes, as permitted by law.",
        },
      ],
    },
    {
      title: "Security of Your Information",
      content:
        "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
    },
    {
      title: "Your Rights and Choices",
      subsections: [
        {
          num: "6.1",
          title: "Account Information",
          content:
            "You may at any time review or change the information in your account or terminate your account by contacting us. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms & Conditions, and/or comply with legal requirements.",
        },
        {
          num: "6.2",
          title: "Emails and Communications",
          content:
            "If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by contacting us using the contact information provided below or by using the unsubscribe feature in the email.",
        },
      ],
    },
    {
      title: "Changes to This Privacy Policy",
      content:
        "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
    },
    {
      title: "Contact Us",
      content:
        "If you have any questions or comments about this Privacy Policy, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: info@myretasker.com",
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("showWaitlist") === "true") {
      setServiceModal(true);
    }
  }, [location]);
  return (
    <div className="support_main">
      <div className="margin-left-60 margin-right">
        <div className="head">
          <p className="title">Our Efficient</p>
          <p className="title support">Support </p>
        </div>

        <div className="support-desc">
          <p className="desc">
            We assist Real Estate Agents in becoming more organized by offering
            meticulously planned services tailored to streamline their daily
            operations and enhance their productivity.
          </p>
          <p className="desc2">Our web and mobile platforms are coming soon.</p>
        </div>
      </div>

      <div className="imgs">
        <div className="mac-class">
          <img src={macBook} alt="macBook" className="photo1" />
          <div className="overlay-efficiant" />
          <div className="macbook-text">
            <p className="early">Get an early access to the platform</p>
            <button className="wishlist" onClick={() => handleOpen()}>
              Join Waitlist
            </button>
          </div>
        </div>

        <div className="iPhone-class">
          <img src={iPhone} alt="iPhone" className="photo2" />
          <div className="overlay-efficiant" />
          <div className="iPhone-text">
            <div className="btns">
              <p className="soon">Coming Soon on</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a>
                  <img src={app_store} alt="play_store" className="app_logo" />
                </a>
                <a>
                  <img src={play_store} alt="app_store" className="play_logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={serviceModal} onClose={handleClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handleClose}
              alt="close-icon"
            />
          </div>
          <div className="overflowAuto">
            <div className="term-modal-title">
              <p className="setTitle">Waitlist</p>
            </div>
            <div className="Container">
              <div className="formContainer">
                <p className="titleTxt">Full Name*</p>
                <input
                  placeholder="Enter your full name"
                  className="textInput"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                />
                {nameError ? <p className="error-text">{nameError}</p> : null}
                <p className="titleTxt">Email Id*</p>
                <input
                  placeholder="Enter your Email"
                  className="textInput"
                  value={email.trim()}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError ? <p className="error-text">{emailError}</p> : null}
                <p className="titleTxt">Contact Number*</p>
                <input
                  placeholder="Enter your Contact Info"
                  className="textInput"
                  value={contact.trim()}
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => {
                    setContact(e.target.value);
                    setContactError("");
                  }}
                />
                {contactError ? (
                  <p className="error-text">{contactError}</p>
                ) : null}
              </div>
              <div className="check-box-div">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  sx={{
                    color: "#d4ad2d",
                    "&.Mui-checked": {
                      color: "#d4ad2d",
                    },
                  }}
                />
                <p className="term-data">
                  I agree to the{" "}
                  <span className="term-text" onClick={handleNDAOpen}>
                    Non-Disclosure Agreement,
                  </span>
                  <span className="term-text" onClick={handleTermOpen}>
                    {" "}
                    Terms & Conditions
                  </span>{" "}
                  and{" "}
                  <span className="term-text" onClick={handlePolicyOpen}>
                    Privacy Policy
                  </span>
                </p>
              </div>
              <div className="button-Container">
                <button
                  disabled={loading}
                  className="button"
                  onClick={() => formValidation()}
                >
                  {loading ? <CircularProgress size={20} /> : "Join Waitlist"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={ndaModal} onClose={handleNDAClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handleNDAClose}
              alt="close-icon"
            />
          </div>
          <div className="term-modal-title">
            <p className="setTitle">Non-Disclosure Agreement</p>
          </div>
          <div style={{ overflowY: "auto" }}>
            <div className="containerTerm">
              <p className="agreementTitle">
                CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT
              </p>
              <p className="agreementTxt">
                This Confidentiality and Non-Disclosure Agreement ("Agreement")
                is made and entered into as of April 1, 2024 by and between
                Worldwide Assistance Solutions LLC/ dba MY RE TASKER, a Florida
                corporation with its principal place of business at 1504 Bay
                Road, Unit 3302 Miami Beach FL 33139 ("Disclosing Party"), and
                the undersigned party ("Receiving Party").
                <br />
                <br />
                <span className="agreementBold">WHEREAS </span>Disclosing Party
                agrees to disclose certain proprietary and confidential
                information to Receiving Party for the purpose of facilitating
                strategic planning and decision-making with regards to the My Re
                Tasker and its proprietary agent support technology platform.
                <br />
                <br />
                <span className="agreementBold">NOW, THEREFORE</span>, in
                consideration of the mutual promises and covenants contained
                herein, and other good and valuable consideration, the receipt
                and sufficiency of which are hereby acknowledged, the parties
                agree as follows:
              </p>
              <ol type="1">
                {NDAList.map((item, index) => {
                  return (
                    <li className="listTxt" key={index}>
                      <span className="agreementBold">{item.title}</span>{" "}
                      {item.description}
                      {item.list && (
                        <ol type="a">
                          {item.list.map((listItem, listIndex) => (
                            <li key={listIndex} className="listTxtInner">
                              {listItem}
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  );
                })}
              </ol>
              <p className="agreementTxt1">
                {" "}
                <span className="agreementBold">IN WITNESS WHEREOF </span>the
                parties have executed this Agreement as of the Effective Date.
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={termModal} onClose={handleTermClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handleTermClose}
              alt="close-icon"
            />
          </div>
          <div className="term-modal-title">
            <p className="setTitle">Terms & Conditions</p>
          </div>
          <div style={{ overflowY: "auto" }}>
            <div className="containerTerm">
              <ol className="termListType">
                {TermsList.map((item, index) => {
                  return (
                    <li className="listTxt">
                      <span className="agreementBold">{item.title}</span>{" "}
                      {item.description}
                      {item.list && (
                        <ul>
                          {item.list.map((listItem, listIndex) => (
                            <li key={listIndex} className="listTxtInner1">
                              {listItem}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={policyModal} onClose={handlePolicyClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handlePolicyClose}
              alt="close-icon"
            />
          </div>
          <div className="term-modal-title">
            <p className="setTitle">Privacy Policy</p>
          </div>

          <div style={{ overflowY: "auto" }}>
            <div className="containerTerm">
              <ol type="1">
                {privacyList.map((item) => {
                  return (
                    <li className="listTxt">
                      <span className="agreementBold">{item.title}</span>
                      <p className="listTxtDec">{item.content} </p>
                      {item.list && (
                        <ul>
                          {item.list.map((list) => {
                            return <li className="listTxtInner1">{list}</li>;
                          })}
                        </ul>
                      )}
                      {item.subsections && (
                        <ol>
                          {item.subsections.map((items) => {
                            return (
                              <li className="privacyInnerList">
                                <span className="agreementBold lineHeight">
                                  {items.num} {"  "}
                                </span>
                                <span className="agreementBold lineHeight">
                                  {items.title}
                                </span>
                                <p className="listTxt">{items.content} </p>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EfficientSupport;
