import React from "react";
import "./privacy.css";
const privacyList = [
  {
    title: "Introduction",
    content: `Worldwide Assistance Solutions LLC, dba MY RE TASKER ("Company," "we," "us," or "our"), operates the MY RE TASKER platform available via website and mobile applications. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service.`,
  },
  {
    title: "Information We Collect",
    content:
      "We may collect information about you in a variety of ways. The information we may collect includes:",
    subsections: [
      {
        num: "2.1",
        title: "Personal Data",
        content:
          "Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Service or when you choose to participate in various activities related to the Service.",
      },
      {
        num: "2.2",
        title: "Derivative Data",
        content:
          "Information our servers automatically collect when you access the Service, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Service.",
      },
      {
        num: "2.3",
        title: "Financial Data",
        content:
          "Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Service.",
      },
      {
        num: "2.4",
        title: "Mobile Device Data",
        content:
          "Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device if you access the Service from a mobile device.",
      },
    ],
  },
  {
    title: "Use of Your Information",
    content: `Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Service to:`,
    list: [
      "Create and manage your account",
      "Process your transactions",
      "Personalize your experience with the Service",
      "Improve our Service",
      "Send you promotional information",
      "Respond to your comments, questions, and customer service requests",
      "Protect against fraudulent, unauthorized, or illegal activity",
      "Monitor and analyze usage and trends to improve your experience with the Service",
    ],
  },
  {
    title: "Disclosure of Your Information",
    content:
      "We may share information we have collected about you in certain situations. Your information may be disclosed as follows:",
    subsections: [
      {
        num: "4.1",
        title: "By Law or to Protect Rights",
        content:
          "If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.",
      },
      {
        num: "4.2",
        title: "Business Transfers",
        content:
          "We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
      },
      {
        num: "4.3",
        title: "Third-Party Service Providers",
        content:
          "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.",
      },
      {
        num: "4.4",
        title: "Marketing Communications",
        content:
          "With your consent, we may share your information with third parties for marketing purposes, as permitted by law.",
      },
    ],
  },
  {
    title: "Security of Your Information",
    content:
      "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
  },
  {
    title: "Your Rights and Choices",
    subsections: [
      {
        num: "6.1",
        title: "Account Information",
        content:
          "You may at any time review or change the information in your account or terminate your account by contacting us. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms & Conditions, and/or comply with legal requirements.",
      },
      {
        num: "6.2",
        title: "Emails and Communications",
        content:
          "If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by contacting us using the contact information provided below or by using the unsubscribe feature in the email.",
      },
    ],
  },
  {
    title: "Changes to This Privacy Policy",
    content:
      "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
  },
  {
    title: "Contact Us",
    content:
      "If you have any questions or comments about this Privacy Policy, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: info@myretasker.com",
  },
];

const Privacy = () => {
  return (
    <div className="about-container">
      <div className="privacy-div">
        <p className="privacyTitle">Privacy Policy</p>
      </div>

      <div className="privacyTerm">
        <ol type="1">
          {privacyList.map((item) => {
            return (
              <li className="listTxt">
                <span className="agreementBold">{item.title}</span>
                <p className="listTxtDec">{item.content} </p>
                {item.list && (
                  <ul>
                    {item.list.map((list) => {
                      return <li className="listTxtInner1">{list}</li>;
                    })}
                  </ul>
                )}
                {item.subsections && (
                  <ol>
                    {item.subsections.map((items) => {
                      return (
                        <li className="privacyInnerList">
                          <span className="agreementBold lineHeight">
                            {items.num} {"  "}
                          </span>
                          <span className="agreementBold lineHeight">
                            {items.title}
                          </span>
                          <p className="listTxt">{items.content} </p>
                        </li>
                      );
                    })}
                  </ol>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Privacy;
