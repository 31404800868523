import React from "react";

const TermsList = [
  {
    title: "Introduction",
    description:
      "Welcome to MY RE TASKER, a platform operated by Worldwide Assistance Solutions LLC. By accessing or using our services (the 'Service'), you agree to be bound by these Terms & Conditions (the 'Terms'). Please read them carefully.",
  },
  {
    title: "Definitions",
    description: "",
    list: [
      '"Company" refers to Worldwide Assistance Solutions LLC, dba MY RE TASKER.',
      `"Service" refers to the Company's SAAS platform available via website and mobile applications.`,
      '"User", "You, and Your" refer to the individual accessing or using the Service.',
    ],
  },
  {
    title: "Acceptance of Terms",
    description:
      "By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use the Service.",
  },
  {
    title: "Eligibility",
    description:
      "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
  },
  {
    title: "Account Registration",
    description:
      "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
  },
  {
    title: "License to Use",
    description:
      "The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
  },
  {
    title: "Prohibited Conduct",
    description:
      "You agree not to engage in any of the following prohibited activities:",
    list: [
      "Using the Service for any illegal purpose.",
      "Attempting to interfere with the proper functioning of the Service.",
      "Using automated systems to access the Service without the Company's prior written permission.",
      "Attempting to gain unauthorized access to any part of the Service.",
    ],
  },
  {
    title: "Fees and Payment",
    description:
      "Some features of the Service may require payment of fees. You agree to pay all applicable fees associated with your use of the Service. The Company reserves the right to change its pricing and fees at any time.",
  },
  {
    title: "Intellectual Property",
    description:
      "All content, trademarks, service marks, trade names, logos, and other intellectual property displayed on the Service are the property of the Company or its licensors. You may not use any of these without the Company's prior written consent.",
  },
  {
    title: "Privacy Policy",
    description:
      "Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.",
  },
  {
    title: "Termination",
    description:
      "The Company may terminate or suspend your access to the Service at any time, with or without cause or notice, effective immediately. Upon termination, your right to use the Service will immediately cease.The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
  },
  {
    title: "Disclaimers",
    description:
      'The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. The Company disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.',
  },
  {
    title: "Limitation of Liability",
    description:
      "In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Service; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party; or (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not the Company has been advised of the possibility of such damages.",
  },
  {
    title: "Governing Law",
    description:
      "These Terms shall be governed and construed in accordance with the laws of the State of Florida, without regard to its conflict of law provisions.",
  },
  {
    title: "Changes to Terms",
    description:
      "The Company reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
  },
  {
    title: "Contact Us",
    description: `If you have any questions about these Terms, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: Info@myretasker.com`,
  },
];

const Term = () => {
  return (
    <div className="about-container">
      <div className="privacy-div">
        <p className="privacyTitle">Terms & Conditions</p>
      </div>

      <div className="privacyTerm">
        <ol type="1">
          {TermsList.map((item, index) => {
            return (
              <li className="listTxt">
                <span className="agreementBold">{item.title}</span>{" "}
                {item.description}
                {item.list && (
                  <ul>
                    {item.list.map((listItem, listIndex) => (
                      <li key={listIndex} className="listTxtInner1">
                        {listItem}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Term;
