import React from "react";
import "./about.css";
import { about } from "../assets";

const About = () => {
  return (
    <div className="about-container">
      <div className="first-section">
        <div className="left-line" />
        <p className="about-intro">
          With our carefully thought-out services, we assist real estate agents
          in becoming more organized.
        </p>
        <div className="right-line" />
      </div>
      <div className="second-section">
        <div className="div-wrapper">
          <img className="about-img" src={about} alt="about" />
          <div className="overlay" />
          <div className="third-section">
            <div className="main-about">
              <p className="inner-main-about">ABOUT US</p>
              <p className="inner-about">ABOUT US</p>
            </div>
          </div>
        </div>
        <div className="about-dec-div">
          <p className="about-info">
            At My RE Tasker, we are committed to transforming how real estate
            agents succeed in their businesses. We recognize the challenges and
            demands of the ever-evolving real estate industry. That’s why we
            offer comprehensive support services tailored specifically for real
            estate professionals.
          </p>
          <p className="about-info m-20">
            Our mission is clear: to empower agents by reducing their workload,
            enabling them to concentrate on their core strengths—building
            relationships, closing deals, and providing exceptional client
            experiences. We believe that with the right support, agents can
            achieve remarkable success and realize their full potential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
