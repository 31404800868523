export const logo = require("../assets/Logo.png");
export const about = require("../assets/New Folder With Items/About.webp");
export const iPhone = require("../assets/New Folder With Items/iPhone.webp");
export const macBook = require("../assets/New Folder With Items/Macbook.webp");
export const play_store = require("../assets/Play_Store.png");
export const app_store = require("../assets/App_Store.png");
export const formPhoto = require("../assets/New Folder With Items/formPhoto.webp");
export const iconDrop = require("../assets/dropdownIcon.png");
export const iconDropup = require("../assets/iconRotated.png");
export const close = require("../assets/close.png");
export const service1 = require("../assets/New Folder With Items/Service1.webp");
export const service2 = require("../assets/New Folder With Items/Service2.webp");
export const service3 = require("../assets/New Folder With Items/Service3.webp");
export const service4 = require("../assets/New Folder With Items/Service4.webp");
export const service5 = require("../assets/New Folder With Items/Service5.webp");
export const service6 = require("../assets/New Folder With Items/Service6.webp");
export const service7 = require("../assets/New Folder With Items/Service7.webp");
export const NavLogo = require("../assets/NavLogo.png");
