import React, { useState } from "react";
import "./requestCall.css";
import { formPhoto } from "../assets";
import {
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { supabase } from "../../SupabaseConfig";
const RequestCall = () => {
  const [value, setValue] = useState("Listing Coordination");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msgError, setMsgError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const formValidation = () => {
    if (name == "") {
      setNameError("Enter Your Name");
    } else {
      setNameError("");
    }
    if (msg == "") {
      setMsgError("Enter Message");
    } else {
      setMsgError("");
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Enter Valid Email");
    } else {
      setEmailError("");
    }
    if (name != "" && /\S+@\S+\.\S+/.test(email) && msg != "") {
      callRequest();
    } else {
    }
  };
  const callRequest = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("callrequest")
      .insert([
        { name: name, email: email, enquiry_subject: value, message: msg },
      ])
      .select();

    if (data) {
      setEmailError("");
      setMsgError("");
      setEmailError("");
      setEmail("");
      setName("");
      setMsg("");
      setLoading(false);
      alert("We rceived your request. Our team will connect with you shortly.");
    } else {
      setLoading(false);
    }
  };
  const dropmenu = [
    {
      label: "Listing Coordination",
    },
    {
      label: "Transaction Coordination",
    },
    {
      label: "Branding & Marketing",
    },
    {
      label: "Admin Services",
    },
    {
      label: "Property Management",
    },
    {
      label: "Accounting Services",
    },
    {
      label: "Compliance Review",
    },
  ];
  return (
    <div id="contactUs" className="main-request-call">
      <div className="main">
        <p className="inner-main">REQUEST CALL</p>
        <p className="inner">REQUEST A CALL</p>
      </div>
      <div className="form-container">
        <div className="form-innerContainer">
          <div className="form-dataContainer">
            <img src={formPhoto} className="formPhoto" />
            <div className="textContainer display-flex">
              <div className="flex-1">
                <p className="titleTxt">Name*</p>
                <input
                  placeholder="Enter your name"
                  className="textInput"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                />
                {nameError ? <p className="error">{nameError}</p> : null}
                <p className="titleTxt">Email Id</p>
                <input
                  placeholder="Enter your Email"
                  className="textInput"
                  value={email.trim()}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError ? <p className="error">{emailError}</p> : null}
                <p className="titleTxt">Inquiry Subject*</p>
                <Select
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="select"
                  sx={{
                    fontWeight: 500,
                    color: "rgba(52, 66, 75, 1)",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    fontSize: isMediumScreen ? "12px" : "14px",
                    width: "100%",
                    height: "44px",
                    alignSelf: "center",
                    borderColor: "rgba(209, 209, 209, 1)",

                    outline: "none",
                    fontFamily: "Montserrat, sans-serif",
                    borderRadius: "0px",
                    "&.MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid rgba(209, 209, 209, 1)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid rgba(209, 209, 209, 1)",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid rgba(209, 209, 209, 1)",
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 160,
                        backgroundColor: "rgba(248, 248, 248, 1)",
                      },
                    },
                  }}
                >
                  {dropmenu.map((item, index) => {
                    return (
                      <MenuItem
                        value={item.label}
                        className="dropdownMenu"
                        key={index}
                        sx={{
                          fontWeight: 500,
                          color: "rgba(55, 66, 75, 1)",
                          padding: "12px",
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                          "@media (max-width: 768px)": {
                            fontSize: "12px",
                          },
                          "@media (max-width: 480px)": {
                            padding: "12px",
                            fontFamily: "Montserrat",
                          },
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="flex-1">
                <p className="titleTxt">Message</p>
                <textarea
                  placeholder="Enter your Message"
                  className="textInput-2"
                  style={{ resize: "none" }}
                  value={msg}
                  onChange={(e) => {
                    setMsg(e.target.value);
                    setMsgError("");
                  }}
                />
                {msgError ? <p className="error">{msgError}</p> : null}
                <div className="button-Container">
                  <button
                    disabled={loading}
                    className="button"
                    onClick={() => formValidation()}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Request a Call"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCall;
